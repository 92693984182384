// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.portal.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { AppEnvironment } from '../app/app-environment';
import packageJson from '../../package.json'

const clientId = '#{BsiConnect--Services--ClientId-Portal}#';
const policyId = 'B2C_1_SUSI';
const authApiBaseUrl = '#{BsiConnect--Services--ApiAuthBaseUrl}#';

export const environment: AppEnvironment = {
  production: true,
  adminApiBaseUrl: '#{BsiConnect--Services--ApiAdminBaseUrl}#',
  authApiBaseUrl: authApiBaseUrl,
  documentApiBaseUrl: '#{BsiConnect--Services--ApiDocumentBaseUrl}#',
  storageApiBaseUrl: '#{BsiConnect--Services--ApiStorageBaseUrl}#',
  clientId: clientId,
  authority: `#{azureb2c-host}#/#{azureb2c-tenant}#/${policyId}`,
  knownAuthorities: ['#{azureb2c-host}#'],
  redirectUrl: '#{BsiConnect--Services--PortalRedirectUrl}#',
  authApiProtectedResource: `${authApiBaseUrl}/auth/token-from-credentials`,
  authApiScope: 'https://#{azureb2c-tenant}#/auth-api/all',
  buildId: '#{Build.BuildId}#',
  buildNumber: '#{Build.BuildNumber}#',
  pipeline: '#{Build.DefinitionName}#',
  branch: '#{Build.SourceBranch}#',
  stage: '#{drz}#',
  repository: '#{Build.Repository.Name}#',
  commit: '#{Build.SourceVersion}#',
  environmentId: '#{Environment.Id}#',
  environmentName: '#{Environment.Name}#',
  version: packageJson.version,
  workspaceUrl: '#{BsiConnect--Services--WorkspaceUrl}#',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

